import React from "react"

export default function Button(
    props: {
        children: React.ReactNode,
        filled?: boolean,
        disabled?: boolean,
        outlined?: boolean,
        onClick?: (e? : React.MouseEvent) => void
        className?: string
    }) {
    const {
        children,
        filled = false,
        disabled = false,
        outlined = false,
        onClick,
        className
    } = props

    return (
        <button
            type="submit"
            onClick={onClick ? onClick : () => { props.onClick }}
            className={`
                ${className ? className : ""}
                group relative flex justify-center
                ${filled && !disabled ?
                    "bg-lime-600 hover:bg-lime-500 text-white" :
                    "hover:bg-slate-100 text-lime-600"
                } 
                ${disabled ?
                    "bg-slate-200 text-slate-400 hover:bg-slate-200" : ""
                } 
                ${outlined ? "ring-lime-400 ring-2" : ""}
                px-3 py-3 font-semibold 
                focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-600 
            `}
            disabled={disabled}
        >
            {children}
        </button>
    )
}