import { gql, useQuery } from "@apollo/client"
import React, {useEffect, useRef, useState } from "react"

type Action = {
    name: JSX.Element | string,
    icon?: React.ReactNode,
    onClick: () => void
} | {
    name: JSX.Element | string,
    icon?: React.ReactNode,
    subactions: (Action | null)[]
}

export default function ContextMenu(props: {
        actions: (Action | null)[],
        eventData?: { id: string, title: string, startTime: moment.Moment, endTime: moment.Moment } | null
        position?: { x: number, y: number },
        onClickOutside: () => void
    }) {
    const [currentActions, setCurrentActions] = useState<Action[]>(props.actions.filter((action) => action !== null) as Action[]);
    const [showingBackButton, setShowingBackButton] = useState<boolean>(false);

    const menuRef = useRef(null);

    useEffect(() => {
        if (props.position && menuRef.current) {
            const menu = menuRef.current as HTMLDivElement;
            const rect = menu.getBoundingClientRect();

            if (rect.right > window.innerWidth) {
                menu.style.left = `${window.innerWidth - rect.width - 20}px`;
            }
            if (rect.bottom > window.innerHeight) {
                menu.style.top = `${window.innerHeight - rect.height - 50}px`;
            }
        }
    }, [props.position]);

    const handleActionClick = (action: Action, event: React.MouseEvent) => {
        event.stopPropagation();
        
        if ('onClick' in action) {
            action.onClick();
            props.onClickOutside();
        } else {
            setCurrentActions(action.subactions.filter((subaction) => subaction !== null) as Action[]);
            setShowingBackButton(true);
        }
    }

    //theres an insane bug where if you have nested absolute containers it completely throws off the mouse position
    //be  sure you are using ContextMenu inside of a relative container
    return (
        <div
            className={`absolute bg-white border border-gray-200 shadow-lg rounded-xl p-2 z-20 flex flex-col backdrop-blur-lg min-w-[200px] ${props.position ? 'bg-opacity-75' : 'bg-opacity-100'}`}
            style={{
                top: `${props.position?.y}px`,
                left: `${props.position?.x}px`,
            }}
            ref={menuRef}
        >
            {/* {showingBackButton && */}
            <button
                onClick={(event) => {
                    event.stopPropagation();
                    setCurrentActions(props.actions.filter((action) => action !== null) as Action[]);
                    setShowingBackButton(false);
                }}
                className={`flex justify-between items-center text-left w-min pr-2 text-gray-700 hover:bg-gray-100 px-2 py-1 rounded-md ${showingBackButton ? '' : 'hidden'}`}
            >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                </svg>
            </button>

            {currentActions.map((action, index) =>
            action !== null && <button
                key={`${action.name}-${index}`}
                onClick={(event) => handleActionClick(action, event)}
                className="flex justify-between items-center text-left w-full text-gray-700 hover:bg-gray-100 px-2 py-1 rounded-md"
            >
                <div className="flex gap-2 items-center pr-3">
                    { action.icon ? action.icon : "" }
                    {action.name}
                </div>
                {'subactions' in action &&
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                    </svg>
                }
            </button>
            )}
        </div>
    )
}