import { SubscriptionAmount, Unit, UnitService } from "modi_backend_gql/src/client/generated/graphql";
import moment from "moment";
import headshot from "../../images/stephanie_remove_bg.png"
import Building from "./Building";

export const subscriptionDescriptions = {
    [SubscriptionAmount.OneTime]: "one time",
    //[SubscriptionAmount.EveryTwoWeeks]: "every two weeks",
    [SubscriptionAmount.EveryFourWeeks]: "every four weeks",
}

export const subscriptionPrices = {
    [SubscriptionAmount.OneTime]: 80,
    //[SubscriptionAmount.EveryTwoWeeks]: 65,
    [SubscriptionAmount.EveryFourWeeks]: 70,
}

export const subscriptionNames = {
    [SubscriptionAmount.OneTime]: "One Time",
    //[SubscriptionAmount.EveryTwoWeeks]: "Every Two Weeks",
    [SubscriptionAmount.EveryFourWeeks]: "Every Four Weeks",
}

export default function UnitServiceView(props : {unitService: UnitService , building : boolean, subscriptionAmount?: SubscriptionAmount, tech_image : string}) {

    const date_string = moment(props.unitService.date).utcOffset(0, false).format('dddd, MMMM Do');
  
    const time_start_string = moment(props.unitService.timeBlock.startTime).utcOffset(0,false).format('h:mm a');
    const time_end_string = moment(props.unitService.timeBlock.endTime).utcOffset(0,false).format('h:mm a');
  
    return (
        <div className='  max-w-md filter drop-shadow-sm relative mb-4 z-10 flex-shrink-0' >
            <div className='rounded-md z-10 border-gray-200 border bg-white overflow-hidden'>
                
                {props.building && <Building unit={props.unitService.unit} /> }
                <div className=' flex flex-1 flex-row items-end justify-between w-full px-4' >
                    <img src={props.tech_image} className='w-28 h-24 flex-shrink-0 object-cover'/>
                    <div className='pb-2'>
                    <p className='text-right text-lg text-lime-600 font-semibold  whitespace-pre-wrap'> Cleaning by {props.unitService.tech.firstName + " " + props.unitService.tech.lastName}</p>
                    
                    <p className=' text-right text-gray-500 font-semibold'>{date_string}</p>
                    <p className='text-right text-sm text-gray-400'>expected {time_start_string + "-" + time_end_string}</p>
                    </div>
                </div>
                {props.subscriptionAmount && <SubscriptionView subscriptionAmount={props.subscriptionAmount} />}
            </div> 
        </div>
    )
}

function SubscriptionView(props: { subscriptionAmount : SubscriptionAmount})
{
    return(
        <div className="flex flex-col bg-lime-500 p-2 text-white font-bold w-full">
            {subscriptionNames[props.subscriptionAmount]}
        </div>
    )
}

