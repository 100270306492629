import { Auth, Hub } from "aws-amplify";
import { CognitoUser, ISignUpResult, CognitoUserSession } from 'amazon-cognito-identity-js';
import { StripeCard, SubscriptionAmount, Unit, UnitService, UnitServiceStatus, User } from "../generated/graphql";
import { CREATE_USER, REMOVE_STRIPE_CARD_ON_FILE, UPDATE_STRIPE_CARD_ON_FILE, USER } from "../user";
import { ApolloClient, HttpLink, InMemoryCache, NormalizedCacheObject, gql } from "@apollo/client";
import fetch from 'cross-fetch';
import { ModiAuthError, ModiAuthErrorValue } from "./ModiAuthError";
import { BOOK_UNIT_SERVICE, BOOK_UNIT_SERVICE_SUBSCRIPTION, UPDATE_UNIT_SERVICE_STATUS } from "../unitService";

import { Stripe, loadStripe } from "@stripe/stripe-js";

const MODI_PASSWORD = 'password';

export enum AuthState {
    SplashScreen = 'SplashScreen',
    LoginScreen = 'LoginScreen',
    ConfirmSignInScreen = 'ConfirmSignInScreen',
    ConfirmSignUpScreen = 'ConfirmSignUpScreen',
    ModiUserCreationScreenSignedIn = 'ModiUserCreationScreenSignedIn',
    ModiUserCreationScreen = 'ModiUserCreationScreen',
    Authenticated = 'Authenticated',
}

export enum GraphQLClientEndpoint {
    localhost = "http://localhost:4000/",
    prod = "https://zzc8nmsz96.execute-api.us-east-1.amazonaws.com/",
    master = "https://yn283qmqxh.execute-api.us-east-1.amazonaws.com/"
}

export enum StripeAPIKEY {
    master = "pk_test_51IR06yLIVAV75AD1DuL4fWOWxGhYbpsqODEj3TSh7G54f1UaTIuImDAKtEn4RUcEEnvQkE0BJNAf8PJ0g6HrmaNU00nfNA74Rw",
    prod = "pk_live_51IR06yLIVAV75AD1hkWTRRjBTHus3ngibFkEWCPm2hf6yT4NWvsa85AOgui4XUl0k4k3DTEZpS8CC8vip0rNDEjc00qHTIb4cJ"
}


//Convert some of these ModiUser methods to ModiAPI.ts and AdminAPI.ts

export class ModiUser {

    graphqlClient : ApolloClient<NormalizedCacheObject>;
    endpoint : GraphQLClientEndpoint = GraphQLClientEndpoint.prod;

    cognitoUser : CognitoUser | undefined = undefined;

    modiUser : User | undefined = undefined;

    phoneNumber : string | undefined = undefined;

    stripe : Stripe | null = null;
    stripeAPIKey : StripeAPIKEY = StripeAPIKEY.prod;

    private internalAuthState : AuthState = AuthState.SplashScreen;
    authStateListeners : ((authState : AuthState) => void)[] = [];

    public get authState() : AuthState {
        return this.internalAuthState;
    }

    private set authState(authState : AuthState) {
        this.internalAuthState = authState;
        this.authStateListeners.forEach((listener) => listener(authState));
        console.log('authState: ', authState);
    }

    constructor() {
        this.graphqlClient = new ApolloClient({
            link: new HttpLink({
              uri: this.endpoint,
              fetch: ModiUser.aswGraphqlFetch,
            }),
            cache: new InMemoryCache(),
            credentials: 'include',
        });


        //TODO: handle killing this subscription when the class is destroyed
        Hub.listen('auth', ({ payload }) => {
            switch (payload.event) {
                case 'autoSignIn':
                    console.log('user automatically signed in!');
                    this.cognitoUser = payload.data as CognitoUser;
                    this.authState = AuthState.ModiUserCreationScreen;
                    break;
                case 'signOut':
                    this.cognitoUser = undefined;
                    this.modiUser = undefined;
                    console.log('user successfully signed out!');
                    break;
                default:
                    console.log('AuthEvent: ', payload);
                    break;
            }
        });

        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.initStripe();
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.checkCognitoUser();
    }

    async initStripe() : Promise<void>
    {
        this.stripe = await loadStripe(this.stripeAPIKey);
    }

    getStripe() : Stripe
    {
        if(this.stripe === null) throw new Error("stripe is undefined");

        return this.stripe;
    }

    getUnit() : Unit | null
    {
        if(this.modiUser === undefined) return null;

        if(this.modiUser.residence === null) return null;

        return this.modiUser.residence as Unit;
    }

    isFreeTrialEligible() : boolean
    {
        if(this.modiUser === undefined) return false;


        //TODO: add an endpoint for this
        return true;
    }

    listAllCards() : StripeCard[]
    {
        if(this.modiUser === undefined) return [];

        const stripeCardsOnFile = this.modiUser?.stripeCardsOnFile;

        if(stripeCardsOnFile === null || stripeCardsOnFile === undefined) return [];

        return stripeCardsOnFile as StripeCard[];
    }

    async removeCardOnFile(cardId : string) : Promise<void>
    {
        if(this.modiUser === undefined) return;

        const stripeCardsOnFile = this.modiUser?.stripeCardsOnFile;

        if(stripeCardsOnFile === null || stripeCardsOnFile === undefined ) return;

        const { data } = await this.graphqlClient.query({
            query: gql(REMOVE_STRIPE_CARD_ON_FILE),
            fetchPolicy: 'network-only',
            variables: {
                cardId: cardId,
            },
        });


        if(data === null || data === undefined) throw new Error("data is undefined");

        if(data.user.removeStripeCardOnFile === null || data.user.removeStripeCardOnFile === undefined) throw new Error("Error removing card on file");

        //force a refresh of the modi user
        await this.checkModiUser();
    }

    async updateUnitServiceStatus(unitServiceId : string, unitServiceStatus : UnitServiceStatus) : Promise<void>
    {
        if(this.modiUser === undefined) return;

        const { data } = await this.graphqlClient.mutate({
            mutation: gql(UPDATE_UNIT_SERVICE_STATUS),
            fetchPolicy: 'network-only',
            variables: {
                unitServiceId: unitServiceId,
                unitServiceStatus: unitServiceStatus,
            },
        });

        if(data === null || data === undefined) throw new Error("data is undefined");

        if(data.user.updateUnitServiceStatus === null || data.user.updateUnitServiceStatus === undefined) throw new Error("Error updating unit service status");
    }

    async bookUnitService(techId : string, date : string, timeBlockId : string, discountCode: string) : Promise<UnitService>
    {
        if(this.modiUser === undefined) throw new Error("modiUser is undefined");

        try {
            //mutation BookUnitService($techId: ID!, $date: String!, $timeBlockId: ID!)
            const { data } = await this.graphqlClient.mutate({
                mutation: gql(BOOK_UNIT_SERVICE),
                fetchPolicy: 'network-only',
                variables: {
                    techId: techId,
                    date: date,
                    timeBlockId: timeBlockId,
                    discountCode: discountCode
                },
            });

            if(!data) throw new Error("data is undefined");

            const bookUnitService = data.bookUnitService;

            if(bookUnitService === null || bookUnitService === undefined) throw new Error("bookUnitService is undefined");

            return bookUnitService as UnitService;
        }catch(error) {
            console.log('error booking unit service: ', error);
            throw error;
        }
    }

    async bookUnitServiceSubscription(unitService : UnitService, subscriptionAmount: SubscriptionAmount)
    {

        if(this.modiUser === undefined) throw new Error("modiUser is undefined");
        if(!this.hasStripeCardOnFile()) throw new Error("no stripe card on file");

        try {
            //mutation BookUnitServiceSubscription($techId: ID!, $date: String!, $timeBlockId: ID!, $subscriptionAmount: SubscriptionAmount!)
            const { data } = await this.graphqlClient.mutate({
                mutation: gql(BOOK_UNIT_SERVICE_SUBSCRIPTION),
                fetchPolicy: 'network-only',
                variables: {
                    techId: unitService.techId,
                    date: unitService.date,
                    timeBlockId: unitService.timeBlockId,
                    subscriptionAmount: subscriptionAmount,
                },
            });
        } catch(error) {
            console.log('error booking unit service subscription: ', error);
        }
    }

    hasStripeCardOnFile() : boolean
    {
        const cards_on_file = this.listAllCards();

        return cards_on_file.length > 0;
    }

    async updateStripeCardOnFile() : Promise<string>
    {
        try {
            const { data } = await this.graphqlClient.mutate({
                mutation: gql(UPDATE_STRIPE_CARD_ON_FILE),
                fetchPolicy: 'network-only',
            });

            if(!data) throw new Error("data is undefined");

            const updateStripeCardOnFile = data.user.updateStripeCardOnFile;

            if(updateStripeCardOnFile === null || updateStripeCardOnFile === undefined) throw new Error("updateStripeCardOnFile is undefined");

            return updateStripeCardOnFile;
        }catch(error) {
            console.log('error updating stripe card on file: ', error);
            throw error;
        }

    }



    async checkCognitoUser() {
        try {
            this.cognitoUser = await Auth.currentAuthenticatedUser();

            if(!this.cognitoUser) throw new Error("cognitoUser is undefined");

            //check if the session is valid
            this.cognitoUser.getSession((err, session) => {
                if(err) {
                    console.log('error getting session: ', err);
                    return;
                }
                console.log('session: ', session);
                void this.checkModiUser();
            });


            await this.checkModiUser();
        } catch(error) {
            const authError = new ModiAuthError(error);
            console.error('authError: ', authError);

            switch(authError.value) {
                case ModiAuthErrorValue.UserNotSignedIn:
                    this.authState = AuthState.LoginScreen;
                    break;
                case ModiAuthErrorValue.ModiUserNotFoundException:
                    this.authState = AuthState.ModiUserCreationScreenSignedIn;
                    break;
                default:
                    console.log('authError: ', authError);
                    break;
            }
        }
    }

    getResidence() : Unit | null
    {
        if(this.modiUser === undefined) return null;

        if(this.modiUser.residence === null) return null;

        return this.modiUser.residence as Unit;
    }

    isTech(): boolean {
        if(!this.modiUser) return false;
        return this.modiUser.firstTechSignInDate !== null;
    }

    async checkModiUser() {
        try {
            //next we'll check on the modi user
            const { data } = await this.graphqlClient.query({ query: gql(USER), fetchPolicy: 'network-only'});
            this.modiUser = data.user as User;
            this.authState = AuthState.Authenticated;
        } catch(error) {
            const authError = new ModiAuthError(error);
            console.error('authError: ', authError);

            switch(authError.value) {
                case ModiAuthErrorValue.ModiUserNotFoundException:
                    this.authState = AuthState.ModiUserCreationScreenSignedIn;
                    break;
                default:
                    console.log('authError: ', authError);
                    break;
            }
        }
    }

    public addAuthStateListener(setAuthState : (authState : AuthState) => void)
    {
        this.authStateListeners.push(setAuthState);
    }

    public getApolloClient() : ApolloClient<NormalizedCacheObject>
    {
        return this.graphqlClient;
    }

    async signIn(phone_number : string) : Promise<void>
    {
        try {
            console.log('signing in with phone number: ', phone_number);
            this.phoneNumber = phone_number;
            this.cognitoUser = await Auth.signIn(phone_number, MODI_PASSWORD);
            this.authState = AuthState.ConfirmSignInScreen;
        } catch (error) {
            const authError = new ModiAuthError(error);
            if(authError !== undefined && authError.value === ModiAuthErrorValue.UserNotFoundException) {
                await this.signUp(phone_number);
                return;
            }
        }
    }

    async confirmSignIn(code : string) : Promise<void>
    {
        try {
            this.cognitoUser = await Auth.confirmSignIn(this.cognitoUser, code, 'SMS_MFA');
            await this.checkModiUser();
        } catch (error) {
            const authError = new ModiAuthError(error);

            console.log('authError: ', authError);
        }
    }

    async confirmSignUp(code : string) : Promise<void>
    {
        if(!this.phoneNumber) throw new Error("confirmSignUp called before signIn");

        try {
            await Auth.confirmSignUp(this.phoneNumber, code);
            //await Auth.signIn(this.phoneNumber, MODI_PASSWORD);

        } catch (error) {
            const authError = new ModiAuthError(error);

            console.log('authError: ', authError);
        }
    }

    async signUp(phone_number : string) : Promise<void>
    {
        console.log('signing up with phone number: ', phone_number);
        try {
            const signUpResult : ISignUpResult = await Auth.signUp({
                username: phone_number,
                password: MODI_PASSWORD,
                attributes: {
                    phone_number,
                },
                autoSignIn: {
                    enabled: true,
                }
            });
            this.authState = AuthState.ConfirmSignUpScreen;
        } catch (error) {
            const authError = new ModiAuthError(error);

            console.log('authError: ', authError);
        }
    }

    async modiUserSignUp(firstName : string, lastName : string, confirmationCode : string | undefined) : Promise<void>
    {

        if(confirmationCode) await this.confirmSignIn(confirmationCode);

        //next we'll check on the modi user
        const { data } = await this.graphqlClient.mutate({
            mutation: gql(CREATE_USER),
            variables:{ firstName : firstName, lastName : lastName },
            fetchPolicy: 'network-only'}
        );

        await this.checkCognitoUser();
    }

    async signOut() : Promise<void>
    {
        try {
            await Auth.signOut();
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }

    public static async aswGraphqlFetch(uri: string, options: { headers: { [key: string]: string } })
    {
        try {
            const cognitoUserSession = await Auth.currentSession();

            //console.log(cognitoUserSession.getIdToken().decodePayload());
            const token = cognitoUserSession.getIdToken().getJwtToken();

            options.headers["Authorization"] = token;
            return fetch(uri, options);
        } catch(error) {
            console.log('error getting cognito user session: ', error);
            throw error;
        }


    }
}
