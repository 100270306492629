export const ADD_TENANT_TO_UNIT = `#graphql
mutation AddTenantToUnit($unitId: ID!) {
    addTenantToUnit(unitId: $unitId) {
        id
        firstName
        lastName
        phone
        stripeCustomerID
        residence {
            id
            unitNumber
            unitType {
                id
                name
                sqft
            }
        }
    }
}
`;

export const UPDATE_STRIPE_CARD_ON_FILE = `#graphql
query UpdateStripeCardOnFile {
    user {
        id
        updateStripeCardOnFile
    }
}
`;


export const CREATE_USER = `#graphql
mutation CreateUser($firstName: String!, $lastName: String!) {
    createUser(firstName: $firstName, lastName: $lastName) {
        id
        firstName
        lastName
        phone
        residence {
            id
            unitNumber
            unitType {
                id
                name
                sqft
            }
            property {
                id
                name
            }
            bookedUnitServices {
                id
                date
                timeBlockId
                timeBlock {
                    id
                    startTime
                    endTime
                }
                techId
                tech {
                    id
                    firstName
                    lastName
                }
                propertyId
                price
                unit {
                    id
                    unitNumber
                    unitType {
                    sqft
                    }
                    tenant {
                    id
                    }
                }
            }
        }
    }
}
`;

export const REMOVE_STRIPE_CARD_ON_FILE = `#graphql
query RemoveStripeCardOnFile($cardId: String!) {
    user {
        id
        removeStripeCardOnFile(cardId: $cardId)
    }
}
`;

export const USER = `#graphql
query User {
    user {

        id
        firstName
        lastName
        phone
        firstTechSignInDate
        stripeCustomerID
        stripeCardsOnFile {
            id
            last4
            brand
            exp_month
            exp_year
            funding
        }
        residence {
            id
            unitNumber
            unitType {
                id
                name
                sqft
            }
            property {
                id
                name
            }
            bookedUnitServices {
                id
                date
                timeBlockId
                timeBlock {
                    id
                    startTime
                    endTime
                }
                techId
                tech {
                    id
                    firstName
                    lastName
                }
                propertyId
                price
                unit {
                    id
                    unitNumber
                    unitType {
                    sqft
                    }
                    tenant {
                    id
                    }
                }
            }
        }
    }
}
`;