import { useEffect, useRef, useState } from "react";
import ThikkkProgressBar from "../components/thikkk-progress-bar";
import Button from "../components/input/Button";
import { useNavigate } from "react-router-dom";
import React from "react";
import Header from "../components/Header";
import { gql, useQuery } from "@apollo/client";
import { USER } from "modi_backend_gql/src/client/user";
import { Unit, UnitService, User } from "modi_backend_gql/src/client/generated/graphql";
import Building from "../components/modi/Building";
import UnitServiceView from "../components/modi/UnitService";
import PwaInstallPopupIOS from 'react-pwa-install-ios';

import mop_icon from "../images/mop_white.png";
import { ArrowRightCircleIcon } from "@heroicons/react/20/solid";
import ContextMenu from "modi_components/src/stories/calendar/ContextMenu";
import { useModiUser } from "modi_backend_gql/src/client/react";
import { ModiUser } from "modi_backend_gql/src/client/react/ModiUser";
import { ChatBubbleLeftEllipsisIcon } from "@heroicons/react/24/outline";
import CardOnFile from "../components/UnitServiceSubscriptionCheckout";
import { tech_image_map } from "./Book";

const dropShadow = {
    shadowColor: "black",
    shadowOffset: {
        width: 1,
        height: 1,
    },
    shadowOpacity: 0.15,
    shadowRadius: 10,
    zIndex: 10,
};

const dropShadowLight = {
    shadowColor: "black",
    shadowOffset: {
        width: 1,
        height: 2,
    },
    shadowOpacity: 0.15,
    shadowRadius: 2,
    zIndex: 10,
};

const modiSupportPhoneNumber = "(512) 987-8856";
const modiSupportEmail = "support@gomodi.com";



export default function Home() {
    
    const { modiUser } = useModiUser(); 

    return (
        <div className="p-4 bg-white">
            <div className="mx-auto max-w-md">
                <div className="py-2">
                    <Building unit={modiUser.getResidence() as Unit} />
                </div>
                <div className="flex flex-row">
                    <ModiBookAServiceButton />
                    <ModiSupportButton />
                </div>
                <InstallIOSPopup/>
                <MyBookedUnitServices modiUser={modiUser} />
                
            </div>
        </div>
    )
}

function MyBookedUnitServices(props : { modiUser : ModiUser })
{
    const residence : Unit = props.modiUser.getResidence() as Unit;

    const unitServices = residence.bookedUnitServices?.map((unitService, index) => unitService ? <UnitServiceView key={unitService?.id} unitService={unitService} building={false} tech_image={tech_image_map[unitService.techId]} /> : <div key={index}>no services found</div> );

    return(
        <div className="mx-auto max-w-md">
            <div>
                <p className="text-gray-700 text-lg font-semibold text-left pt-4 border-b">Upcoming Services</p>
            </div>
            <div className="py-2">
                {unitServices}
                {unitServices?.length == 0 && <div className="text-gray-500 text-center">No upcoming services found.</div>}
            </div>
        </div>
    )

}

function ModiBookAServiceButton()
{
    const navigate = useNavigate();

    return(
        <div onClick={() => navigate("/book")} className="bg-lime-500 text-white font-bold text-lg rounded-lg p-2 m-1 flex flex-col items-start justify-center w-1/2">
            <div className="justify-self-end w-full self-end flex flex-row items-center justify-between">
                
                <span className=" w-full">Book a cleaning</span>
                <ArrowRightCircleIcon className="w-8 h-8 mr-2 text-white" />
                
            </div>
            
        </div>
    )
}

function ModiSupportButton()
{
    const [showContextMenu, setShowContextMenu] = useState<boolean>(false);
    const [contextMenuPosition, setContextMenuPosition] = useState<{ x: number, y: number } | null>(null);

    const openContextMenu = (e?: React.MouseEvent) => {
        setShowContextMenu(true);
        const rect = (e?.target as HTMLDivElement).getBoundingClientRect();
        setContextMenuPosition({ x: rect.x, y: rect.y + rect.height });
    }

    const closeContextMenu = () => {
        setShowContextMenu(false);
    }

    return(
        <>
            <Button
                className="border m-1 w-1/2 rounded-lg shadow-none transition-all hover:shadow-xl bg-gray-500 cursor-pointer hover:text-gray-500 hover:-translate-y-1 text-white flex flex-col items-start justify-between"
                onClick={(e) => openContextMenu(e)}
            >
            <div className="flex flex-row items-center justify-between">
                <ChatBubbleLeftEllipsisIcon className="w-12 h-12 mr-2 font-bold" />
                <span className="text-left text-sm">Click here to contact support</span>
                
            </div>
        </Button>
        {showContextMenu &&
            <ContextMenu
                actions={[
                    {
                        name: <a href={`tel: ${modiSupportPhoneNumber}`}>{modiSupportPhoneNumber}</a>,
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        onClick: () => {},
                        icon: (
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                            </svg>
                        )
                    },
                    {
                        name: <a href={`mailto: ${modiSupportEmail}`}>{modiSupportEmail}</a>,
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        onClick: () => {},
                        icon: (
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                <path strokeLinecap="round" d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25" />
                            </svg>
                        )
                    }
                ]}
                onClickOutside={closeContextMenu}
                position={contextMenuPosition ? contextMenuPosition : undefined}
            />
        }
        </>
    );
}

function InstallIOSPopup()
{

    return(
        <div className="">
            <div>
                <p className="text-gray-700 text-lg font-semibold text-left pt-4 border-b select-none">Install Modi On Your Phone</p>
            </div>
            <div className="flex items-center justify-center flex-row p-4 bg-gray-50">
                <img className="h-16 w-16 mr-8 border rounded-lg border-gray-500" src="/apple-touch-icon.png" alt="Modi" />
                <div className="select-none">
                    <div>To install modi on your iPhone:</div>
                    <ol className="list-decimal list-inside">
                        <li>Open this website in Safari.</li>
                        <li>Tap the <img className="inline w-6 h-6" src="/share.png" alt="share" /> button.</li>
                        <li>Tap <b>Add to Home Screen</b>.</li>
                        <li>Tap <b>Add</b>.</li>
                    </ol>
                </div>
            </div>
        </div>
    )
}

/*<PwaInstallPopupIOS delay={2} lang="en" appIcon="/apple-touch-icon.png">
    </PwaInstallPopupIOS>*/
