//createProperty(name: String!, address: String!, city: String!, state: String!, organizationId: ID!): Property
export const CREATE_PROPERTY = `mutation CreateProperty($name: String!, $address: AddressInput!, $regionId: String!) {
    createProperty(name: $name, address: $address, regionId: $regionId) {
        id
        name
        address
        {
            street
            city
            state
        }
        regionId
    }
}`;


export const GET_AVAILIBLE_UNITS = `query GetAvailibleUnits($propertyId: ID!) {
    getAvailibleUnits(propertyId: $propertyId) {
        id
        unitNumber
        unitType {
            name
            sqft
        }
    }
}`;

export const GET_PROPERTY = `#graphql
query GetProperty($id: ID!) {
    property(id: $id) {
        id
        name
        address {
            street
            city
            state
        }
        units {
            id
            unitNumber
            unitTypeId
            tenant {
                firstName
                lastName
                phone
            }
        }
        techs {
            id
            firstName
            lastName
        }
        unitTypes {
            id
            name
            propertyId
            sqft
        }
        officeHours {
            id
            name
            startTime
            endTime
            rRule
        }
    }
}`;

export const PROPERTIES = `query Properties {
    properties {
        id
        name
        address {
            street
            city
        }
    }
}`;

export const ADD_TIME_BLOCK_TO_PROPERTY_OFFICE_HOURS = `mutation AddTimeBlockToPropertyOfficeHours($timeBlock: TimeBlockInput!, $propertyId: ID!) {
    addTimeBlockToPropertyOfficeHours(timeBlock: $timeBlock, propertyId: $propertyId) {
        id
        name
        officeHours {
            id
            name
            startTime
            endTime
        }
    }
}`;

export const DELETE_PROPERTY = `mutation DeleteProperty($id: ID!) {
    deleteProperty(id: $id) {
        id
    }
}`;

export const GET_PROPERTY_SCHEDULE = `#graphql
query GetPropertySchedule($id: ID!, $startDate: String!, $endDate: String!) {
    property(id: $id) {
        propertySchedule(startDate: $startDate, endDate: $endDate) {
            timeBlocks {
                id
                name
                startTime
                endTime
                rRule
                exDate
            }
            unitServices {
                id
                date
                timeBlockId
                status
                timeBlock {
                    id
                    startTime
                    endTime
                }
                techId
                tenant {
                    id
                    firstName
                    lastName
                }
                unit {
                    id
                    unitNumber
                }
                tech {
                    id
                    firstName
                    lastName
                }
                propertyId
                price
            }
            allocatedTimeBlocks {
                id
                propertyId
                regionId
                techId
                timeBlockId
                date
                rRule
                timeBlock {
                    id
                    name
                    startTime
                    endTime
                    rRule
                    exDate
                }
                tech {
                    id
                    firstName
                    lastName
                }
            }
        }
    }
}
`;
