export const VALIDATE_COUPON = `#graphql
mutation ValidateCoupon($code: String!) {
    validateCoupon(code: $code) {
      isValid
      message
      discountAmount
      discountPercentage
    }
}
`;
