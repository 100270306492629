import { StripeCard, SubscriptionAmount, UnitService } from "modi_backend_gql/src/client/generated/graphql";
import { useModiUser } from "modi_backend_gql/src/client/react";

import visa_icon from "../images/visa_icon.jpeg";
import mastercard_icon from "../images/mastercard_icon.png";
import Button from "./input/Button";
import { Elements, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "react-loading";

export default function UnitServiceSubscriptionCheckout(props : { unitService : UnitService, subscriptionAmount : SubscriptionAmount})
{

  const { modiUser } = useModiUser();

  const [hasCard, setHasCard] = useState<boolean>(modiUser.hasStripeCardOnFile());

  const [isBooking, setIsBooking] = useState(false);

  const [isDoneBooking, setIsDoneBooking] = useState(false);

  const navigate = useNavigate();

  const handleCheckout = async () => {
    try {
      console.log("checkout");
      setIsBooking(true);
      await modiUser.bookUnitServiceSubscription(props.unitService, props.subscriptionAmount);
      setIsBooking(false);
      setIsDoneBooking(true);
    } catch (e) {
      setIsBooking(false);
      console.log(e);
    }
  };

  if(isDoneBooking) return (
    <div>
      <div>Booking Successful!</div>
      <Button onClick={() => navigate("/")} className="bg-lime-500 rounded-lg mt-2 p-4 w-full font-semibold text-white">
        Go Home
      </Button>
    </div>
  );

  if(isBooking) return (<Loading type="spin" color="#10B981" height={200} width={200} />);

  return(
    <div>
      <CardOnFile setHasCard={setHasCard} hasCard={hasCard}  />
      <button onClick={handleCheckout} className="bg-lime-500 rounded-lg mt-2 p-4 w-full font-semibold text-white disabled:bg-slate-300" disabled={!hasCard}>
        Start Subscription
      </button>
    </div>
  );

}

function CardOnFile(props : { setHasCard : (hasCard : boolean) => void, hasCard : boolean })
{

  

  switch(props.hasCard)
  {
      case false:
          return <NoCardOnFileView setHasCard={props.setHasCard} />
      case true:
          return <WalletView setHasCard={props.setHasCard}/>
  }
}

function WalletView(props : { setHasCard : (hasCard : boolean) => void }){

  const { modiUser } = useModiUser();

  return(
    <div className="flex flex-col w-full">
        <div>
          <p className="text-gray-700 text-lg font-semibold text-left pt-4 border-b select-none">Payment Method On File</p>
        </div>
        <div className="p-2 bg-gray-50">
          {
              modiUser.listAllCards().map((card) => {
                return <CardLineItemView setHasCard={props.setHasCard} key={card.id} lineItem={card} />
              })
          }
        </div>
    </div>
  )
}

function NoCardOnFileView(props: { setHasCard : (hasCard : boolean) => void })
{
    const { modiUser } = useModiUser();

    const [setup_intent_client_secret, setSetupIntentClientSecret] = useState<string | undefined>(undefined);

    useEffect(() => {
        modiUser.updateStripeCardOnFile().then((client_secret) => {
        setSetupIntentClientSecret(client_secret);
        }).catch(e => console.log(e));
    }, []);

    return(
      <div className="flex flex-col">
        <div className="flex flex-col border rounded-lg p-2 w-full">
          {   setup_intent_client_secret ?
            <Elements stripe={modiUser.getStripe()} options={{ clientSecret: setup_intent_client_secret } }>
                <AddPaymentMethodView setHasCard={props.setHasCard} setup_intent_client_secret={setup_intent_client_secret} />
            </Elements> : 
            <div className="w-full h-96 animate-pulse bg-gray-100 "/>
          }   
        </div>
        <p className="text-sm text-center text-gray-500 p-2">Note : Modi saves your payment method using Stripe secure payments to enable subscriptions and make future checkouts faster.</p>
      </div>
    )
}

function AddPaymentMethodView(props : {setup_intent_client_secret: string, setHasCard : (hasCard : boolean) => void })
{
    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState<string | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);

    const { modiUser } = useModiUser();

    const handleSubmit = async (e) => {
      e.preventDefault();
  
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
  
      setIsLoading(true);
  
      const { error } = await stripe.confirmSetup({
        elements,
        redirect: "if_required",
      });
  
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error !== undefined && error?.type === "card_error" || error?.type === "validation_error") {
        setMessage(error.message);
      }
  
      setIsLoading(false);
      //make sure the modi user is up to date then refresh the view
      await modiUser.checkModiUser();
      props.setHasCard(true);
    };

    useEffect(() => {
      if (!stripe) {
        return;
      }
  
      const clientSecret = props.setup_intent_client_secret;
  
      if (!clientSecret) {
        return;
      }
  
      stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
          if (!setupIntent) {
            return;
          }
          switch (setupIntent.status) {
              case "succeeded":
                setMessage("Payment succeeded!");
                break;
              case "processing":
                setMessage("Your payment is processing.");
                break;
              case "requires_payment_method":
                break;
              default:
                console.log(setupIntent.status);
                setMessage("Something went wrong.");
                break;
          }
      }).catch(e => console.log(e))
    }, [stripe]);

    return(
      <form id="payment-form" onSubmit={handleSubmit}>
          <PaymentElement id="payment-element" options={{
            layout: "tabs",
          }} />
          <div id="error-message" role="alert" className="text-red-600 pt-2 w-96 whitespace-pre-wrap">
            {message}
          </div>
          <button className="w-full bg-lime-500 p-2 text-lg rounded-lg mt-4 text-white font-semibold" disabled={!stripe || !elements} id="submit">
              <span id="button-text">
                Add Card
              </span>
          </button>
      </form>
    )

}




function CardLineItemView(props : { lineItem : StripeCard, setHasCard : (isEditing : boolean) => void })
{
  const { modiUser } = useModiUser();

  const handleRemove = () => {
      modiUser.removeCardOnFile(props.lineItem.id).then(() => {
          console.log("card removed");
          props.setHasCard(false);
      }).catch(e => console.log(e));
  }

  return(
    <div className="flex flex-row items-center justify-between w-full p-2">
        <div className="flex flex-row">
            <div className="w-12 h-12 rounded bg-white flex items-center justify-center">
                <img src={props.lineItem.brand === "visa" ? visa_icon : mastercard_icon} className="w-full" />
            </div>
            <div className="ml-2">
                <div className="text-gray-700 font-semibold">Ending with {props.lineItem.last4}</div>
                <div className="text-gray-500">Expires {props.lineItem.exp_month}/{props.lineItem.exp_year}</div>
            </div>
        </div>
        <button className="ml-2 text-gray-500 hover:text-gray-700" onClick={handleRemove}>Remove</button>
    </div>
  );
}