import { Color } from "../constants/colors"

export default function Button(
    props: {
        children: React.ReactNode,
        filled?: boolean,
        disabled?: boolean,
        outlined?: boolean,
        onClick?: () => void
        className?: string,
        color?: Color
    }) {

    const {
        children,
        filled = false,
        disabled = false,
        outlined = false,
        onClick,
        className
    } = props

    const button_color = props.color ? props.color : "lime";

    const filled_class = `bg-${button_color}-400 hover:bg-${button_color}-500 text-white`;

    const default_class = `hover:bg-slate-100 text-${button_color}-500`;

    const disabled_class = "bg-slate-200 text-slate-400 hover:bg-slate-200";

    const outlined_class = `ring-${button_color}-400 ring-2`;

    const base_class = `
        group relative flex justify-center rounded-md 
        px-3 py-2 text-sm font-semibold 
        focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${button_color}-600 
    `;

    const classes = `${base_class} ${filled ? filled_class : default_class} ${disabled ? disabled_class : ""} ${outlined ? outlined_class : ""} ${className ? className : ""}`;

    return (
        <button
            type="submit"
            onClick={onClick ? onClick : () => {}}
            className={classes}
            disabled={disabled}
            style={props.filled ? {backgroundColor: "rgb(132 204 22)"} : {}}
        >
            {children}
        </button>
    )
}