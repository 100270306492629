import { Unit } from "modi_backend_gql/src/client/generated/graphql";
import theView from "../../images/the_view.png";

export default function Building(props : { unit?: Unit | null }) {

    if(props.unit == null) return null;
  
    return (
      <div className='w-full justify-around rounded-2xl flex flex-row items-center px-2 h-16 z-0'>
        <img
          src={theView}
          className='w-28 h-16 rounded-lg object-contain'
        />
        <div className='flex flex-col w-24'>
          <p className=' text-gray-600 font-semibold text-center'>Unit</p>
          <p className='text-gray-500 text-center'>{props.unit.unitNumber}</p>
        </div>
        <div className='flex flex-col w-24'>
          <p className=' text-gray-600 font-semibold text-center'>Type</p>
          <p className='text-gray-500 text-center'>{props.unit.unitType.name}</p>
        </div>   
        <div className='flex flex-col w-20'> 
          <p className=' text-gray-600 font-semibold text-center'>Size</p>
          <p className='text-gray-500 text-center'>{props.unit.unitType.sqft}<span className="text-xs text-center pl-1 ">sqft</span> </p>
          
        </div>
      </div>
    )
  }