import { useModiUser } from "modi_backend_gql/src/client/react";
import AccountSetupTenant from "./AccountSetupUnitTenant";
import { useState } from "react";
import { Unit } from "modi_backend_gql/src/client/generated/graphql";

export default function TenantAuthenticator(props : { children : JSX.Element }){
    const { modiUser } = useModiUser();


    switch(modiUser.getResidence() === null){
        case true:
            return <AccountSetupTenant checkModiUser={() => console.log("picked Unit!")} />;
        case false:
            return props.children;
    }



}       