/* eslint-disable react/react-in-jsx-scope */
import { useState } from "react";
import Button from "../input/Button";
import Dropdown from "../input/Dropdown";

import { ADD_TENANT_TO_UNIT } from "modi_backend_gql/src/client/user";
import { gql, useMutation, useQuery } from "@apollo/client";
import { GET_AVAILIBLE_UNITS } from "modi_backend_gql/src/client/property";
import { Unit } from "modi_backend_gql/src/client/generated/graphql";

export default function AccountSetupTenant(props : { checkModiUser : () => void  } ) 
{
    const queryParameters = new URLSearchParams(window.location.search)
    const propertyId = queryParameters.get("p");

    const [apartmentNotFound, setApartmentNotFound] = useState(false);

    const { loading, error, data  } = useQuery(gql(GET_AVAILIBLE_UNITS),{
        variables: { propertyId: propertyId },
        onError: (error) => {
            console.log('error', error);
            setApartmentNotFound(true);
        },
        onCompleted: (data) => {
            console.log('data', data);
        }
    });

    return (
        <div className="flex h-full items-center justify-center backdrop-blur-sm">
            <div className="w-full min-h-full bg-white">
                {apartmentNotFound && <ApartmentNotFound />}
                {!loading && !error && <ChooseUnitTitle />}
                {!loading && !error && <ApartmentUnitSelectionForm checkModiUser={props.checkModiUser} data={data} />}
            </div>
        </div>
    );
}

function ApartmentNotFound(){
    return(
        <div className=" flex flex-col justify-center mx-auto md:max-w-md lg:max-w-lg xl:max-w-xl p-4">
            <p className="text-xl text-left font-black text-gray-600">UH OH 😧 We couldn&apos;t find your apartment</p>
            <p className=" text-gray-500 py-2">Please scan one of our fancy QR codes on a poster around your property or use the magic link your property manager sent you.</p>
            <p className="font-semibold text-lime-600">Don&apos;t worry! We saved your progress! All you have to do is pick your apartment unit.</p>
        </div>
    )
}

function ChooseUnitTitle()
{
    return(
        <div className=" flex flex-col justify-center mx-auto md:max-w-md lg:max-w-lg xl:max-w-xl p-4">
            
            <p className="text-xl text-left font-black text-gray-600">Thanks for scanning our fancy QR Code 🙌</p>
            <p className="text-center text-gray-500">Now, let&apos;s get you set up with your apartment.</p>
            <div className="w-full h-32 bg-center bg-no-repeat bg-contain bg-[url(images/the_view.png)]"  />
            <p>Choose your unit from the dropdown below</p>
        </div>
    )
}



function ApartmentUnitSelectionForm(props : { checkModiUser : () => void, data : 
    { 
        getAvailibleUnits : Unit[]
    } | undefined
})
{

    const [apartment, setApartment] = useState<string | undefined>(undefined);

    const [checkBox_1, setCheckBox_1] = useState(false);
    const [checkBox_2, setCheckBox_2] = useState(false);
 
    const apartmentUnits = props.data?.getAvailibleUnits;
    const apartmentUnitNames : string[] | undefined = apartmentUnits?.map((unit) => unit.unitNumber);


    const unitId = apartmentUnits?.find((unit : Unit) => unit.unitNumber === apartment)?.id


    const [addTenantToUnit, { loading , error }] = useMutation(gql(ADD_TENANT_TO_UNIT), {
        variables: { 
            unitId: unitId,
        },
        onCompleted: (data) => {
            console.log(data);
            props.checkModiUser();
        },
        onError: (error) => {
            console.error(error);
        }
    });

    const handleApartmentChange = (event) => {
        setApartment(event);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        await addTenantToUnit();

        window.location.reload();
    }

    return(
        <form onSubmit={handleSubmit} className="flex flex-col gap-3 mx-auto md:max-w-md lg:max-w-lg xl:max-w-xl p-4 pt-0">
            { apartmentUnitNames && 
                <Dropdown
                    options={apartmentUnitNames}
                    label="Apartment Number"
                    onChange={handleApartmentChange}
                    selectedOption={apartment || ""}
                    onNoResultsMessage="We didn&apos;t find any units with that number 😢"
                />
            }
            {apartment && 
                <CheckBox label="I checked and this is my apartment number 👍" onChange={setCheckBox_1} checked={false} required={true} className="animate-fade-up" /> 
            }
            {apartment && checkBox_1 &&
                <CheckBox label="I'm super sure this is my apartment quit asking!! 👀" onChange={setCheckBox_2} checked={false} required={true} className="animate-fade-up" />
            }
            <Button className="mb-4" filled disabled={!checkBox_1 || !checkBox_2} >
                modi time!!!
            </Button>
        </form>
    );
}

function CheckBox(props : { label : string, onChange : (value : boolean) => void, checked : boolean , required? : boolean, className? : string }){
    return(
        <div className={ "flex w-full items-start " + props.className }>
            <input type="checkbox" name="agreement" id="agreement" className="rounded text-lime-600" onChange={(event) => props.onChange(event.target.checked) } required={props.required} />
            <label htmlFor="agreement" className="pl-1 text-gray-700">
                {props.label}
            </label>
        </div>
    );
}