import { useLocation } from "react-router-dom"
import { SubscriptionAmount, UnitService } from "modi_backend_gql/src/client/generated/graphql";
import UnitServiceView, { subscriptionDescriptions, subscriptionPrices } from "../components/modi/UnitService";
import { useModiUser } from "modi_backend_gql/src/client/react";
import moment from "moment";
import UnitServiceSubscriptionCheckout from "../components/UnitServiceSubscriptionCheckout";
import UnitServiceCheckout from "../components/UnitServiceCheckout";
import { tech_image_map } from "../pages/Book";
import {useEffect, useState} from "react";


export default function Pay() {

  interface CouponDiscount {
    type: string;
    amount: number;
  }

  const { modiUser } = useModiUser();

  const location = useLocation();
  if(location.state == null) throw new Error("unitService is null");

  const { unitService, subscriptionAmount, couponDiscount } = location.state as { unitService: UnitService, subscriptionAmount: SubscriptionAmount, couponDiscount: CouponDiscount };

  const isFreeTrialEligible = modiUser.isFreeTrialEligible() && subscriptionAmount !== SubscriptionAmount.OneTime;

  const [discountAmount, setDiscountAmount] = useState(0);

  useEffect(() => {
    if (couponDiscount.type === 'amount' && couponDiscount.amount)
      setDiscountAmount(couponDiscount.amount / 100);
    else if (couponDiscount.type === 'percentage' && couponDiscount.amount)
      setDiscountAmount(subscriptionPrices[subscriptionAmount] * (couponDiscount.amount / 100));
  })

  const subtotal = () => {
      if (couponDiscount.type === 'amount' && couponDiscount.amount) {
        return subscriptionPrices[subscriptionAmount] - couponDiscount.amount / 100;
      } else if (couponDiscount.type === 'percentage' && couponDiscount.amount) {
        return subscriptionPrices[subscriptionAmount] - (subscriptionPrices[subscriptionAmount] * (couponDiscount.amount / 100));
      }
      return subscriptionPrices[subscriptionAmount];
  }

  const tax = parseFloat((0.0825 * subtotal()).toFixed(2));

  const total = isFreeTrialEligible ? 0 : (subtotal() + tax);

  const CheckoutElement = subscriptionAmount === SubscriptionAmount.OneTime ? <UnitServiceCheckout unitService={unitService} total={total} /> : <UnitServiceSubscriptionCheckout unitService={unitService} subscriptionAmount={subscriptionAmount}  />;

  return (
    <div className="bg-white flex flex-col items-center justify-center w-full p-2">
      <div className="flex flex-col w-full sm:max-w-md py-4">
        <div className="font-black text-2xl text-gray-800 text-left py-4">Checkout</div>
        <UnitServiceView unitService={unitService} building subscriptionAmount={subscriptionAmount} tech_image={tech_image_map[unitService.techId]} />
        <CheckoutReciept total={total} subtotal={subtotal()} tax={tax} unitService={unitService} subscriptionAmount={subscriptionAmount} isFreeTrialEligible={isFreeTrialEligible} discountAmount={discountAmount} />
        <div className="py-2">
        {CheckoutElement}
        </div>
      </div>
    </div>
  )
}

function CheckoutReciept(props: { total: number, subtotal: number, tax: number, unitService: UnitService, subscriptionAmount: SubscriptionAmount, isFreeTrialEligible: boolean, discountAmount: number })
{
  return(
    <div className="flex flex-col w-full">

      <div className="flex flex-col">
        <div className="flex flex-row justify-between border-b">
          <div className="text-gray-500 text-left pt-4 ">Subtotal</div>
          <div className="text-gray-500 text-left pt-4 ">{subscriptionPrices[props.subscriptionAmount]}</div>
        </div>
        {props.discountAmount > 0 && <div className="flex flex-row justify-between border-b">
          <div className="text-gray-500 text-left pt-4 ">Discount</div>
          <div className="text-gray-500 text-left pt-4 ">- ${props.discountAmount}</div>
        </div>}
        {props.isFreeTrialEligible && <div className="flex flex-row justify-between border-b">
          <div className="text-gray-500 text-left pt-4 ">First Cleaning Free</div>
            <div className="text-gray-500 text-left pt-4 ">-${props.subtotal}</div>
          </div>
        }
        <div className="flex flex-row justify-between border-b">
          <div className="text-gray-500 text-left pt-4 ">Tax</div>
          {props.isFreeTrialEligible ?  <div className="text-gray-500 text-left pt-4 ">${(0).toFixed(2)}</div> : <div className="text-gray-500 text-left pt-4 ">${props.tax.toFixed(2)}</div>}
        </div>
        <div className="flex flex-row justify-between">
          <div className="text-gray-700 text-lg font-semibold text-left pt-4 ">Total</div>
          <div className="text-gray-700 text-lg font-semibold text-left pt-4 ">${props.total.toFixed(2)}</div>
        </div>
      </div>
      {props.subscriptionAmount !== SubscriptionAmount.OneTime && <p className="text-gray-500 pt-2">Then pay ${props.subtotal} + tax {subscriptionDescriptions[props.subscriptionAmount]} starting {moment(props.unitService.date).format('dddd, MMMM Do')}</p>}
    </div>
  );
}
