import SignIn from './SignIn';
import SignUp from './SignUp';
import Verification from './Verification';


import { ApolloProvider, ApolloClient, NormalizedCacheObject } from '@apollo/client';
import SplashScreen from './SplashScreen';

import Header, { HeaderUser } from './Header';

export default function ModiAuthenticator(props : { 
    children : JSX.Element, 
    authState : string, 
    apolloClient? : ApolloClient<NormalizedCacheObject>, 
    phoneNumber : string | undefined,
    signIn : (phoneNumber : string) => void,
    confirmSignIn : (code : string) => void,
    confirmSignUp : (code : string) => void,
    modiUserSignUp : (firstName : string, lastName : string, code: string | undefined) => Promise<void>,
    headerLinks? : { name: string, href: string }[]
    user : { firstName : string, lastName : string, phone : string } | undefined
})
{  
    return (
        <div className="h-screen w-screen flex flex-col relative bg-white ">
            <Header user={props.user as HeaderUser} links={props.headerLinks} />
            <AuthenticatorSwitchCase {...props}/>
        </div>
    );
}

function AuthenticatorSwitchCase(props : { 
    children : JSX.Element, 
    authState : string, 
    apolloClient? : ApolloClient<NormalizedCacheObject>, 
    phoneNumber : string | undefined,
    signIn : (phoneNumber : string) => void,
    confirmSignIn : (code : string) => void,
    confirmSignUp : (code : string) => void,
    modiUserSignUp : (firstName : string, lastName : string, code : string | undefined) => Promise<void>,
    headerLinks? : { name: string, href: string }[]
})
{
    switch (props.authState) {
        case "LoginScreen":
            return <SignIn signIn={props.signIn} />;
        case "ConfirmSignInScreen":
            return <Verification phoneNumber={props.phoneNumber} authReturn={props.confirmSignIn} />;
        case "ConfirmSignUpScreen":
            return <Verification phoneNumber={props.phoneNumber} authReturn={props.confirmSignUp} />;
        case "ModiUserCreationScreen":
            return <SignUp modiUserSignUp={props.modiUserSignUp} userIsSignedIn={false} />;
        case "ModiUserCreationScreenSignedIn":
            return <SignUp modiUserSignUp={props.modiUserSignUp} userIsSignedIn={true} />;
        case "Authenticated":
            return (
                <>
                    {
                        props.apolloClient ? <ApolloProvider client={props.apolloClient}> {props.children}</ApolloProvider> : props.children
                    }
                </>
            );
        case "SplashScreen":
            return <SplashScreen />;
        default:
            return <div>Something went wrong</div>;
    }
}