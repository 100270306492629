
import { hover } from '@testing-library/user-event/dist/hover';
import chroma from 'chroma-js';
import React, { useState } from 'react';
import { AvailibleUnitServicesResponse, UnitService } from 'modi_backend_gql/src/client/generated/graphql';
import { dayIsInMonth, hasDayPassed, isWeekDay } from 'modi_backend_gql/src/util/dates';
import moment, { Moment } from 'moment';

const f = chroma.scale(['#FFFFFF', '#a3e635']);


//pass down on date click and leave this as its own component


//TODO: pre select a day to show the time blocks

type HeatMapDay = {
    day: Moment,
    unitServices: UnitService[]
}

export function ModiHeatmapCalendar(props: { setSelectedDate: React.Dispatch<React.SetStateAction<Date | undefined>>, selectedDate : Date | undefined, availibleUnitServices: UnitService[] })
{
    
    const months = getMonthObjects(props.availibleUnitServices);

    console.log(months)

    const month_components = [...months.keys()].map((month) => <ModiMonthSquare setSelectedDate={props.setSelectedDate} selectedDate={props.selectedDate} key={month.valueOf()} days={months.get(month)} month={month} />);

    if(month_components.length === 0) return <LoadingHeatMap />;

    return (
        <div className="w-full">
            { month_components }
        </div>
    );
}

function LoadingHeatMap()
{

    const day_components = [...Array(14).keys()].map((day, index) => <TombStoneModiDaySquare key={index} day={undefined} />);

    return (
        <div className="w-full">
            <div className="animate-pulse grid grid-cols-7 gap-2">
                { day_components}
            </div>
        </div>
    );
}

function getMonthObjects(availibleUnitServiceDays?: UnitService[]): Map<string, HeatMapDay[]> {
    if (!availibleUnitServiceDays || availibleUnitServiceDays.length === 0) return new Map<string, HeatMapDay[]>();
    const months = new Map<string, HeatMapDay[]>();

    const firstMonthDays = new Set(availibleUnitServiceDays.map((availible) => new Date(availible.date).getUTCMonth()));

    [...firstMonthDays.entries()].forEach(([month, index]) => {
        const unitServicesThisMonth = availibleUnitServiceDays.filter((unitService) => new Date(unitService.date).getUTCMonth() === month);

        const weeks = [...new Set(unitServicesThisMonth.map((unitService) => moment(unitService.date).week()))];

        const firstDayOfWeekForMonth = moment().month(month).startOf('month').day();

        const days = [...Array(moment().month(month).daysInMonth()).keys()].map((day) => {
            const date = moment().month(month).date(day + 1 - firstDayOfWeekForMonth);
            const unitServicesThisDay = unitServicesThisMonth.filter((unitService) => new Date(unitService.date).getUTCDate() === date.date());
            return { day: date, unitServices: unitServicesThisDay };
        })
        .filter((day) => weeks.includes(day.day.week()));

        months.set(moment().month(month).format("MMMM"), days);
    });

    return months;
}



function ModiCalendarHeader()
{
    const days = ["S", "M", "T", "W", "T", "F", "S"]

    const day_components = days.map((day, index) => <ModiCalendarHeaderItem key={index} day={day} />);

    return(
        <div className="grid grid-cols-7 gap-2 col-span-7 w-full">
            { day_components }
        </div>
    )
}

function ModiMonthSquare(props: { days?: HeatMapDay[], month: string, setSelectedDate: React.Dispatch<React.SetStateAction<Date | undefined>>, selectedDate: Date | undefined })
{
    if(!props.days || props.days.length === 0) return <div></div>;

    const day_components = props.days?.map((day) => {
    
        return (<ModiDaySquare 
            selectedDate={props.selectedDate}
            key={ day.day.toDate().valueOf() } 
            heatMapDay={day} 
            setSelectedDate={props.setSelectedDate}
        />)
    });

    return (
        <div className="grid grid-cols-7 gap-2 rounded-lg filter w-full"> 
            <div className='col-span-7 text-gray-400 font-semibold border-b text-lg border-gray-200'>{ props.month }</div>
            <ModiCalendarHeader />
            { day_components }
        </div>
    );
}

function isValidDay(day: Date)
{
    return !hasDayPassed(day) && isWeekDay(day);
}

function ModiCalendarHeaderItem(props: { day: string })
{
    return (
        <div className="w-9 h-9 flex items-center justify-center text-gray-400">
            { props.day }
        </div>
    );
}

function ModiDaySquare(props: { heatMapDay: HeatMapDay, setSelectedDate: React.Dispatch<React.SetStateAction<Date | undefined>>, selectedDate: Date | undefined })
{

    //const color = f(props.cost_level / 4).hex();
    //const [backgroundColor, setBackgroundColor] = useState<string>(color);

    const date = new Date(props.heatMapDay.day.toDate());

    if(props.heatMapDay.unitServices?.length==0) return <PaddingModiDaySquare key={date.valueOf()}/>;

    const selected = props.selectedDate && 
                     date.getFullYear() === props.selectedDate.getFullYear() &&
                     date.getMonth() === props.selectedDate.getMonth() &&
                     date.getDate() === props.selectedDate.getDate();

    const selected_class = selected ? " bg-lime-500 text-white " : " bg-gray-50 hover:bg-gray-200";

    //const cheapDay = props.day.price ? props.day.price < 80 : false;

    //const cheapDayHighlight = cheapDay ? " bg-lime-50 text-lime-500" : " text-gray-600";

    return (
        <div key={date.valueOf()} className={"aspect-square p-1 w-12 select-none  cursor-default flex flex-col items-center justify-center rounded-lg font-light  " + selected_class}
            onClick={() => props.setSelectedDate(date)}>
            <div className={selected ? 'text-white' : 'text-gray-900' }>{ date.getUTCDate() }</div>
            
        </div>
    );

    //<div className='text-sm font-semibold'>${ props.day.price }</div>
}

function TombStoneModiDaySquare(props : { day?: Date })
{
    return (
        <div className="w-10 h-10 select-none flex items-center justify-center rounded-lg font-light bg-gray-100 text-gray-300">
            { props.day?.getUTCDate()}
        </div>
    );
}

function PaddingModiDaySquare()
{
    return (
        <div className="w-10 h-10 select-none flex items-center justify-center rounded-lg bg-gray-50">

        </div>
    );
}


