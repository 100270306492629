import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { Auth } from 'aws-amplify'
import { UserCircleIcon } from '@heroicons/react/20/solid'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export interface HeaderUser {
  firstName : string,
  lastName : string,
  phone : string
}

export default function Header(props : { links? : { name : string, href : string }[], user? : HeaderUser }) {
  const base_class = "inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium";
  const selected_classname = "border-lime-500 text-gray-900 " + base_class;
  const unselected_classname = "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 " + base_class;

  return (
      <div className='w-full h-16 z-50 '>
        <Disclosure as="nav" className="border-b border-gray-200 bg-white">
          {({ open }) => (
            <>
              <div className="mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex h-16 justify-between">
                  <div className="flex">
                    <div className="flex flex-shrink-0 items-center cursor-pointer">
                      <a href='/'>
                        <img
                          className="block h-8 w-auto lg:hidden"
                          src="/logo-black.png"
                          alt="Modi"
                        />
                        <img
                          className="hidden h-8 w-auto lg:block"
                          src="/logo-black.png"
                          alt="Modi"
                        />
                      </a>
                    </div>
                    <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                    {props.links && props.links.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={unselected_classname}
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </div>
                  <DesktopMenuButton user={props.user} />
                  <div className="-mr-2 flex items-center sm:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-lime-500 focus:ring-offset-2">
                      <span className="sr-only">Open main menu</span>
                      {open ? <XMarkIcon className="block h-6 w-6" aria-hidden="true" /> : <Bars3Icon className="block h-6 w-6" aria-hidden="true" />}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>
              <ModiDisclosurePanel user={props.user}  />
            </>
          )}
        </Disclosure>
      </div>
  )
}

function ModiDisclosurePanel(props : { user? : HeaderUser }) {
  return (
    <Disclosure.Panel className="sm:hidden">
      <div className="border-t border-gray-200 pt-4 pb-3">
        <div className="flex items-center px-4">
          <div className="flex-shrink-0">
            <UserCircleIcon className="h-10 w-10 text-gray-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
          {props.user === undefined ? <>
            <div className="bg-gray-200 w-16 h-4 rounded-xl animate-pulse mb-1"/>
            <div className="bg-gray-200 w-28 h-3 rounded-xl animate-pulse"/>
          </> : <>
            <div className="text-base font-medium text-gray-800">{props.user.firstName} {props.user.lastName}</div>
            <div className="text-sm font-medium text-gray-500">{props.user.phone}</div>
          </>}
          </div>
        </div>
        <div className="mt-3 space-y-1">
          <Disclosure.Button
            as="a"
            onClick={() => Auth.signOut()}
            className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800 hover:cursor-pointer">
            Sign Out
          </Disclosure.Button>
        </div>
      </div>
    </Disclosure.Panel>
  );
}

function DesktopMenuButton(props : { user? : HeaderUser }) {
  
  return (
    <div className="hidden sm:ml-6 sm:flex sm:items-center">

      {/* Profile dropdown */}
      <Menu as="div" className="relative ml-3">
        <div>
          <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-lime-500 focus:ring-offset-2">
            <span className="sr-only">Open user menu</span>
            <UserCircleIcon className="h-9 w-9 rounded-full text-gray-400" aria-hidden="true" />
            
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <Menu.Item>
              <div className="ml-3">
                {props.user === undefined ? <>
                  <div className="bg-gray-200 w-16 h-4 rounded-xl animate-pulse mb-1"/>
                  <div className="bg-gray-200 w-28 h-3 rounded-xl animate-pulse"/>
                </> : <>
                  <div className="text-sm font-medium text-gray-800">
                    {props.user.firstName} {props.user.lastName}
                  </div>
                  <div className="text-xs font-medium text-gray-500">
                    {props.user.phone}
                  </div>
                </>}
                
                
              </div>
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a onClick={() => Auth.signOut()} className={classNames(
                  active ? 'bg-gray-100' : '',
                  'block px-4 py-2 text-sm text-gray-700 hover:cursor-pointer'
                )}>Sign out</a>
              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
