import { Elements, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { UnitService } from "modi_backend_gql/src/client/generated/graphql";
import { useModiUser } from "modi_backend_gql/src/client/react";
import Button from "modi_components/src/stories/input/Button";
import { useEffect, useState } from "react";
import Loading from "react-loading";
import { useNavigate } from "react-router-dom";

export default function UnitServiceCheckout(props : { unitService : UnitService, total : number })
{
  const { modiUser } = useModiUser();

  const client_secret = props.unitService.paymentIntentClientSecret;

  if(!client_secret) return (
      <div>
        Error: no client secret. Something went wrong trying to load checkout.
      </div>
  )

  return (
      <Elements stripe={modiUser.getStripe()} options={{ clientSecret: client_secret } }>
        <Checkout unitService={props.unitService} total={props.total} />
      </Elements>
  );
}

export function Checkout(props : { unitService : UnitService, total : number })
{
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<string | undefined>(undefined);

  const [isBooking, setIsBooking] = useState(false);

  const [isDoneBooking, setIsDoneBooking] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    setIsBooking(true);
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: "https://tenant.gomodi.com",
      },
      redirect: "if_required",
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error !== undefined && error?.type === "card_error" || error?.type === "validation_error") {
      setMessage(error.message);
    }

    setIsBooking(false);
    setIsDoneBooking(true);

  };

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = props.unitService.paymentIntentClientSecret;

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      if (!paymentIntent) {
        return;
      }
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("You must provide a payment method.");
          break;
        default:
          console.log(paymentIntent.status);
          //setMessage("Something went wrong.");
          break;
      }
    }).catch(e => console.log(e))
  }, [stripe]);


  const payButton = (
      <button className="w-full disabled:bg-gray-400 bg-lime-500 p-2 text-lg rounded-lg mt-4 text-white font-semibold" disabled={!stripe || !elements} id="submit">
        <span id="button-text">
          Pay ${props.total.toFixed(2)}
        </span>
      </button>
  );

  const doneBookingButton =(
      <div>
        <div>Booking Successful!</div>
        <Button onClick={() => navigate("/")} className="bg-lime-500 rounded-lg mt-2 p-4 w-full font-semibold text-white">
          Go Home
        </Button>
      </div>
  );

  let ButtonElement = isBooking ? <Loading type="spin" color="#10B981" height={200} width={200} /> : payButton;

  if(isDoneBooking) ButtonElement = doneBookingButton;

  return(
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" options={{
          layout: "tabs",
        }} />

        <div id="error-message" role="alert" className="text-red-600 pt-2 w-96 whitespace-pre-wrap">
          {message}
        </div>
        {ButtonElement}
      </form>
  )
}
