import React, {useEffect, useState} from "react";
import { ModiHeatmapCalendar } from "../components/ModiHeatmapCalendar";
import { TimeBlock, UnitService, User } from "modi_backend_gql/src/server/generated/graphql";
import { GET_AVAILIBLE_UNIT_SERVICES } from "modi_backend_gql/src/client/unitService";
import kyle_headshot from "../images/28397a1e-995c-4bca-a7f9-7f4ef1e946fb.png";
import tom_headshot from "../images/a742ce1d-2280-42db-88cd-fcfc005af7e2.png";
import { useNavigate } from "react-router-dom";
import {gql, useMutation, useQuery} from "@apollo/client";
import moment from "moment";
import { useModiUser } from "modi_backend_gql/src/client/react";
import { SubscriptionAmount } from "modi_backend_gql/src/client/generated/graphql";
import { subscriptionDescriptions, subscriptionPrices } from "../components/modi/UnitService";
import {VALIDATE_COUPON} from "../modi_backend_gql/src/client/validateCoupon";

export const tech_image_map = {
    "28397a1e-995c-4bca-a7f9-7f4ef1e946fb": kyle_headshot, //kyle
    "a742ce1d-2280-42db-88cd-fcfc005af7e2" : tom_headshot, //tom
};

interface CouponDiscount {
    type: string;
    amount: number;
}

export default function Book()
{
    const { modiUser } = useModiUser();

    const [selectedDate, setSelectedDate] = React.useState<Date | undefined>(undefined);
    const [selectedTime, setSelectedTime] = React.useState<TimeBlock | undefined>(undefined);
    const [selectedTech, setSelectedTech] = React.useState<User | undefined>(undefined);

    const [discountCode, setDiscountCode] = useState('');
    const [couponDiscount, setCouponDiscount] = useState<CouponDiscount>({
        type: '',
        amount: 0,
    });

    const [isBookingService, setIsBookingService] = useState(false);

    const [discountStatus, setDiscountStatus] = useState('');

    const [selectedSubscriptionAmount, setSelectedSubscriptionAmount] = React.useState<SubscriptionAmount | undefined>(undefined);

    const { loading, error : q_error, data } = useQuery(gql(GET_AVAILIBLE_UNIT_SERVICES), {
        onCompleted: (data) => console.log("data", data),
    });

    const availibleUnitServiceResponse : UnitService[]  = (!loading) ? data.user.residence.availibleUnitServices.unitServices as UnitService[] : [];

    const unitCleanings_by_chosen_day  = availibleUnitServiceResponse.filter(unitService => {
        const cleaningDate = moment(unitService.date).utcOffset(0, false);

        if(selectedDate === undefined) return false;
        return cleaningDate.isSame(selectedDate, "day");
    }).flat();

    const unitCleanings_by_chosen_time = unitCleanings_by_chosen_day.filter(unitCleaning => {
        if(selectedTime === undefined) return false;
        if(unitCleaning == null) return false;
        return unitCleaning.timeBlock.id === selectedTime.id;
    });

    const [validateCoupon, { error, loading: couponLoading }] = useMutation(gql(VALIDATE_COUPON), {
        variables: {
            code: discountCode,
        },
        onCompleted: (data) => {
            if (data && data.validateCoupon) {
                const isValid = data.validateCoupon.isValid;

                if (isValid) {
                    if (data.validateCoupon.discountAmount != null) {
                        setCouponDiscount({
                            type: 'amount',
                            amount: data.validateCoupon.discountAmount
                        });
                    } else if (data.validateCoupon.discountPercentage != null) {
                        setCouponDiscount({
                            type: 'percentage',
                            amount: data.validateCoupon.discountPercentage
                        });
                    }

                    setDiscountStatus('success');
                } else {
                    setDiscountStatus('invalid');
                }
            }
        },
        onError: (error) => {
            setDiscountStatus('errpr');
            console.log('ERROR: ', error);
        }
    });

    useEffect(() => setSelectedTech(undefined), [selectedTime]);

    useEffect(() => setSelectedTime(undefined), [selectedDate]);

    const navigate = useNavigate();

    const handleSubscriptionEligibilityCheck = async () => {
        setIsBookingService(true);

        const selectedUnitService : UnitService = unitCleanings_by_chosen_time.find(unitService => unitService?.tech.id === selectedTech?.id) as UnitService;

        if(selectedSubscriptionAmount === SubscriptionAmount.OneTime)
        {
            const checkoutUnitService = await modiUser.bookUnitService(
                selectedUnitService.tech.id,
                selectedUnitService.date,
                selectedUnitService.timeBlock.id,
                discountCode
            );

            navigate("/pay", { state: {
                unitService : checkoutUnitService,
                subscriptionAmount: selectedSubscriptionAmount,
                couponDiscount: couponDiscount
            }});

        } else
        {
            const checkoutUnitService = {
                id: selectedUnitService.id,
                price: selectedUnitService.price,
                timeBlock: selectedUnitService.timeBlock,
                tech: selectedUnitService.tech,
                unit: modiUser.getUnit(),
                tenant: modiUser.modiUser,
                date: selectedUnitService.date,
                isSubscription: true,
                timeBlockId: selectedUnitService.timeBlock.id,
                techId: selectedUnitService.tech.id,
                propertyId: selectedUnitService.propertyId,
                status: selectedUnitService.status,
            } as UnitService;

            navigate("/pay", { state: {
                unitService : checkoutUnitService,
                subscriptionAmount: selectedSubscriptionAmount,
                couponDiscount: ''
            }});
        }
        setIsBookingService(false);
    }

    const userIsFreeTrialEligible = modiUser.isFreeTrialEligible();

    const handleDiscountStatusMessage = () => {
        if (discountStatus !== '') {
            if (discountStatus === 'success')
                return <p className="text-green-500 text-sm">Discount applied successfully!</p>
            if (discountStatus === 'invalid')
                return <p className="text-red-500 text-sm">Coupon is invalid</p>
            if (discountStatus === 'error')
                return <p className="text-red-500 text-sm">There was an error processing the discount</p>
        }
    }

    const calculatedDiscount = (fullPrice: number) => {
        if (couponDiscount.type === 'amount')
            return fullPrice - couponDiscount.amount / 100;
        else if (couponDiscount.type === 'percentage')
            return fullPrice - (fullPrice * (couponDiscount.amount / 100))
        else
            return fullPrice
    }

    return(
        <div className="w-screen pb-10 flex flex-col items-center justify-start bg-white">
            <div className="mx-auto w-full md:max-w-md lg:max-w-lg xl:max-w-xl px-4">
                <div className="font-black text-2xl text-gray-800 text-left pt-4">Pick a day</div>
                <ModiHeatmapCalendar setSelectedDate={setSelectedDate} selectedDate={selectedDate} availibleUnitServices={availibleUnitServiceResponse} />
                <div className="font-black text-2xl text-gray-800 text-left py-4">Pick a time</div>
                {selectedDate && <ModiTimeChoice timeSlots={unitCleanings_by_chosen_day as UnitService[]} setSelectedTime={setSelectedTime} selectedTime={selectedTime} /> }
                <div className="font-black text-2xl text-gray-800 text-left py-4">Pick a cleaner</div>
                {selectedTime && <ModiTechChoice techs={unitCleanings_by_chosen_time as UnitService[]} setSelectedTech={setSelectedTech} selectedTech={selectedTech} />}
                <div className="font-black text-2xl text-gray-800 text-left py-4">Pick a subscription</div>
                <p className="text-gray-500 text-sm">If you pick a subscription your first cleaning is free.</p>
                {selectedTech && <ModiSubscriptionChoice setSelectedSubscriptionAmount={setSelectedSubscriptionAmount} selectedSubscriptionAmount={selectedSubscriptionAmount} userIsFreeTrialEligible={userIsFreeTrialEligible} couponDiscount={couponDiscount}/>}
                {selectedDate && selectedTime && selectedTech && selectedSubscriptionAmount &&
                    <p className="font-semibold text-gray-500 pb-2">
                        Book {selectedTech?.firstName } on {moment(selectedDate).utcOffset(0,false).format("dddd, MMMM Do YYYY")}
                        {" "} at {moment(selectedTime?.startTime).utcOffset(0, false).format("h:mm A")}
                        {" "} {userIsFreeTrialEligible && selectedSubscriptionAmount !== SubscriptionAmount.OneTime && "for a free then "}
                        { " " + subscriptionDescriptions[selectedSubscriptionAmount]}
                        {" "} for ${selectedSubscriptionAmount === SubscriptionAmount.OneTime ? calculatedDiscount(subscriptionPrices[selectedSubscriptionAmount]) : subscriptionPrices[selectedSubscriptionAmount]} { selectedSubscriptionAmount !== SubscriptionAmount.OneTime && "each time"}.
                    </p>
                }

                {selectedTech && selectedSubscriptionAmount === SubscriptionAmount.OneTime && <div style={{ marginBottom: 20 }}>
                    <input
                        type="text"
                        id="discount-code"
                        placeholder="Discount Code"
                        className={`w-1/2 rounded-md uppercase ring-gray-300 ring-1 border-transparent focus:ring-1 focus:ring-lime-500 focus:border-transparent p-2 mt-4`}
                        value={discountCode}
                        onChange={(e) => setDiscountCode(e.target.value)}
                    />

                    <button
                        type="button"
                        className="mt-4 bg-[#568C56] disabled:bg-gray-400 text-white font-bold py-2 px-4 rounded shadow-lg hover:shadow-sm transition duration-300 ease-in-out"
                        style={{ marginLeft: 15 }}
                        onClick={async () => await validateCoupon()}
                        disabled={loading}
                    >
                        Verify Code
                    </button>
                    {handleDiscountStatusMessage()}
                </div>}

                <button onClick={() => handleSubscriptionEligibilityCheck()} className="bg-lime-500 rounded-lg p-4 w-full font-semibold text-white disabled:bg-slate-300" disabled={!(selectedDate && selectedTime && selectedTech && selectedSubscriptionAmount && !couponLoading && !isBookingService)}>
                    Book Now
                </button>
            </div>
        </div>
    )
}

function ModiSubscriptionChoice(props : { setSelectedSubscriptionAmount: (subscriptionAmount: SubscriptionAmount) => void, selectedSubscriptionAmount?: SubscriptionAmount, userIsFreeTrialEligible: boolean, couponDiscount: CouponDiscount })
{
    const subscriptionAmounts = [
        { id: "1", price: 80, name: "One Time ONLY", value: SubscriptionAmount.OneTime, hasFreeTrial: false },
        { id: "3", price: 70, name: "Every Four Weeks", value: SubscriptionAmount.EveryFourWeeks, hasFreeTrial: true },
        //{ id: "2", price: 65, name: "Every Two Weeks", value: SubscriptionAmount.EveryTwoWeeks, hasFreeTrial: true },
    ];

    const subscription_components = subscriptionAmounts.map(subscription =>
        <SubscriptionAmountView
            key={subscription.id}
            subscriptionAmount={subscription.value}
            selectedSubscriptionAmount={props.selectedSubscriptionAmount}
            setSelectedSubscriptionAmount={props.setSelectedSubscriptionAmount}
            price={subscription.price}
            name={subscription.name}
            freeTrial={subscription.hasFreeTrial && props.userIsFreeTrialEligible}
            couponDiscount={props.couponDiscount}
        />);

    return(
        <div className="">
            {subscription_components}
        </div>
    )
}

function SubscriptionAmountView( props : {
    subscriptionAmount: SubscriptionAmount,
    setSelectedSubscriptionAmount: (subscriptionAmount: SubscriptionAmount) => void,
    price: number,
    name: string,
    selectedSubscriptionAmount : SubscriptionAmount | undefined
    freeTrial: boolean
    couponDiscount: CouponDiscount
}) {

    const selected = props.selectedSubscriptionAmount ? props.selectedSubscriptionAmount === props.subscriptionAmount : false;

    const bg = props.freeTrial ? "bg-lime-100" : "bg-gray-50";

    const selected_class = selected ? " bg-lime-500 text-white " : (bg + "  text-gray-500");

    const totalAmount = () => {
        if (props.couponDiscount.type === 'amount')
            return props.price - props.couponDiscount.amount / 100;
        else if (props.couponDiscount.type === 'percentage')
            return props.price - (props.price * (props.couponDiscount.amount / 100))
        else
            return props.price
    }

    const displayPriceBeforeDiscount = (discountType: string) => {
        if (discountType !== '') {
            return <span className="line-through pr-2">{"$" + props.price}</span>
        }
        return
    }

    return (
        <div onClick={() => props.setSelectedSubscriptionAmount(props.subscriptionAmount)} className=' relative z-10 flex-shrink-0 mb-2' >
            <div className={'rounded-lg z-10 ' + selected_class}>
                <div className=' flex flex-1 flex-row items-center justify-between w-full p-2' >
                    <p className={'text-xl text-right font-semibold ' + selected_class}>
                        {
                            props.name
                        }
                    </p>
                    { props.freeTrial ? <p className='text-right font-semibold'><span className="line-through pr-2">{"$" + props.price}</span>FREE!</p> : <p className='text-right font-semibold'>{displayPriceBeforeDiscount(props.couponDiscount.type)} {"$" + totalAmount() }</p>}
                </div>
            </div>
        </div>
    )
}


function ModiTimeChoice(props : { timeSlots : UnitService[], setSelectedTime: (timeBlock: TimeBlock) => void, selectedTime?: TimeBlock})
{

    const timeblock_set = new Set(props.timeSlots.map(unitService => unitService.timeBlock));

    const timeblock_array = [...timeblock_set.values()].sort((a, b) => {
        return new Date(a.startTime).getTime() - new Date(b.startTime).getTime();
    });

    const price_array : number[] = timeblock_array.map(timeBlock => {
        const matched_slots = props.timeSlots.filter(unitService => unitService.timeBlock.id === timeBlock.id);
        if(matched_slots == null || matched_slots.length === 0 ) throw new Error("matched_slots is null");
        if(matched_slots.length === 1) return matched_slots[0].price;
        return matched_slots.reduce((prev, curr) => Math.min(prev, curr.price), 10000);
    });

    const timeblock_components = timeblock_array.map((timeBlock, index) => <TimeSlot key={timeBlock.id} price={price_array[index]} timeBlock={timeBlock} selectedTime={props.selectedTime} setSelectedTime={props.setSelectedTime} />);

    return(
        <div className="grid grid-cols-3 ">
            {timeblock_components}
        </div>
    )
}

function TimeSlot(props : { timeBlock : TimeBlock, setSelectedTime: (timeBlock: TimeBlock) => void, selectedTime?: TimeBlock, price: number }) {

    const selected = props.selectedTime ? props.selectedTime.id === props.timeBlock.id : false;

    const selected_class = selected ? " bg-lime-500 text-white " : " bg-gray-50 ";

    const timeblock_string = moment(props.timeBlock.startTime).utcOffset(0,false).format("h:mm A");

    return(
        <div onClick={() => props.setSelectedTime(props.timeBlock)} className={ 'cursor-default rounded-lg p-2 flex flex-col items-start justify-center mx-1 my-1 ' + selected_class }>
            { <p className={selected ? ' text-white font-semibold' : 'text-gray-500 font-semibold '}  >{timeblock_string}</p> }

        </div >
    );

    //<p className='text-sm font-semibold ml-1'>${props.price}</p>
}

function ModiTechChoice(props : { techs : UnitService[], setSelectedTech: (tech: User) => void, selectedTech: User | undefined})
{
    const tech_components = props.techs?.map(tech => <TechView key={tech.id} cleaner={tech.tech} selectedTech={props.selectedTech} setSelectedTech={props.setSelectedTech} price={tech.price} />);

    return(
        <div className="">
            {tech_components}
        </div>
    )
}

function TechView( props : { cleaner: User, setSelectedTech: (tech: User) => void,  selectedTech : User | undefined, price: number}) {

    const selected = props.selectedTech ? props.selectedTech?.id === props.cleaner.id : false;

    const selected_class = selected ? " bg-lime-500 text-white " : " bg-gray-50 text-gray-500";

    return (
        <div onClick={() => props.setSelectedTech(props.cleaner)} className=' rounded-lg relative z-10 flex-shrink-0 mb-4' >
            <div className={'rounded-lg z-10 ' + selected_class}>
                <div className=' flex flex-1 flex-row items-start justify-between w-full px-2 h-32 pl-4 pb-0' >
                    <img src={tech_image_map[props.cleaner.id]} className='w-40 h-32 flex-shrink-0 object-cover' />
                    <div className='p-2 relative'>
                        <p className={'text-xl text-right font-semibold ' + selected_class}>
                            {
                                //only first letter of last name
                                props.cleaner.firstName + " " + props.cleaner.lastName.charAt(0) + "."
                            }
                        </p>

                    </div>
                </div>
            </div>
        </div>
    )
}
