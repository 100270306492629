import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Home from './pages/Home';
import ServiceSelection from './pages/Book';
import { useModiUser } from 'modi_backend_gql/src/client/react';
import ModiAuthenticator from 'modi_components/src/stories/auth/ModiAuthenticator';
import TenantAuthenticator from './components/login/TenantAuthenticator';
import Pay from './pages/Pay';


//TODO: Configure deploy to point to dynamoDB permanently
//TODO: Integration tests
Amplify.configure(awsconfig);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />
  },
  {
    path: "/book",
    element: <ServiceSelection />
  },
  {
    path: "/pay",
    element: <Pay />
  }
]);

function AppRoot()
{
  const {
    modiUser, 
    authState,
    signIn,
    confirmSignIn,
    confirmSignUp,
    modiUserSignUp
  } = useModiUser();

  return (
    <React.StrictMode>
      <ModiAuthenticator 
        authState={authState}
        signIn={signIn}
        confirmSignIn={confirmSignIn}
        confirmSignUp={confirmSignUp}
        modiUserSignUp={modiUserSignUp}
        phoneNumber={modiUser.phoneNumber}
        apolloClient={modiUser.getApolloClient()} 
        user={modiUser.modiUser}
        >
        <TenantAuthenticator>
          <RouterProvider router={router}/>
        </TenantAuthenticator>
      </ModiAuthenticator>
    </React.StrictMode>
  );
}


root.render(<AppRoot />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
